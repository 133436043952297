import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddDistributorComponent} from './distributor-add/add-distributor.component';
import {DistributorsListComponent} from './distributors-list/distributors-list.component';
import {EditDistributorComponent} from './distributor-edit/edit-distributor.component';
import {CountriesListComponent} from './country/country-list/countries-list.component';
import {AddCountryComponent} from './country/country-add/add-country.component';
import {EditCountryComponent} from './country/country-edit/edit-country.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Distributors'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: DistributorsListComponent,
        data: {
          title: 'DistributorList'
        }
      },
      {
        path: 'add',
        component: AddDistributorComponent,
        data: {
          title: 'AddDistributor'
        }
      },
      {
        path: 'edit/:id',
        component: EditDistributorComponent,
        data: {
          title: 'EditDistributor'
        }
      },
      {
        path: 'countries',
        data: {
          title: 'Countries'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'list',
            component: CountriesListComponent,
            data: {
              title: 'Country list'
            }
          },
          {
            path: 'add',
            component: AddCountryComponent,
            data: {
              title: 'Add country'
            }
          },
          {
            path: 'edit/:id',
            component: EditCountryComponent,
            data: {
              title: 'Edit country'
            }
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DistributorsRoutingModule {}
