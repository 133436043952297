<div class="sidebar">
  <app-sidebar-header></app-sidebar-header>
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-title">
        Menu
      </li>
      <li class="divider"></li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/dashboard']">
          <i class="icon-speedometer"></i> Dashboard
        </a>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-pencil-square-o"></i> Content</a>
        <a [routerLink]="['/content']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-newspaper-o"></i> News</a>
            <a [routerLink]="['/content/news']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/news/list']">
                  <i class="fa fa-newspaper-o"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/news/add']">
                  <i class="fa fa-newspaper-o"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-file-code-o"></i> Pages</a>
            <a [routerLink]="['/content/pages']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/pages/list']">
                  <i class="fa fa-file-code-o"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/pages/add']">
                  <i class="fa fa-file-code-o"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/slider']">
              <i class="fa fa-camera"></i> Home slider</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/applicationGroups/list']">
              <i class="fa fa-photo"></i> Applications</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/files/']">
              <i class="fa fa-file"></i> Files</a>
          </li>
        </ul>
      </li>
      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="icon-puzzle"></i> Products</a>
        <a [routerLink]="['/products']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/list']">
              <i class="icon-puzzle"></i> List</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/add']">
              <i class="icon-puzzle"></i> Add</a>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-puzzle"></i> Categories</a>
            <a [routerLink]="['/products/categories']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/categories/list']">
                  <i class="icon-puzzle"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/categories/add']">
                  <i class="icon-puzzle"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-puzzle"></i> Features</a>
            <a [routerLink]="['/products/features']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/features/list']">
                  <i class="icon-puzzle"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/features/add']">
                  <i class="icon-puzzle"></i> Add</a>
              </li>
            </ul>
          </li>
        </ul>

      </li>
      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-building"></i> Distributors</a>
        <a [routerLink]="['/distributors']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/list']">
              <i class="fa fa-building"></i> List</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/add']">
              <i class="fa fa-building"></i> Add</a>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-globe"></i> Countries</a>
            <a [routerLink]="['/distributors/countries']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/countries/list']">
                  <i class="fa fa-globe"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/countries/add']">
                  <i class="fa fa-globe"></i> Add</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" *ngIf="(user | async)?.isAdmin" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-users"></i> Users</a>
        <a [routerLink]="['/users']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/users/list']">
              <i class="fa fa-building"></i> List</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/users/add']">
              <i class="fa fa-building"></i> Add</a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-building"></i> Configurator</a>
        <a [routerLink]="['/configurators']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/configurators/list']">
              <i class="fa fa-building"></i> List</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/lumen-output-range/list']">
              <i class="fa fa-table"></i>Lumen Output Range</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/dependencies/list']">
              <i class="fa fa-retweet"></i>Dependencies</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <app-sidebar-footer></app-sidebar-footer>
</div>
