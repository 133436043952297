import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
//import decode from 'jwt-decode';
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

import {environment} from 'environments/environment';
import 'rxjs/add/operator/map';
import {catchError, tap} from 'rxjs/operators';
import {ErrorService} from 'app/error.service';
import {UserLanguage} from 'app/user/user-language.model';
import {User} from 'app/user/user.model';
import {UserResponse} from 'app/user/user-response.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserService {
    private apiUrl: string;
    private headers: HttpHeaders;
    private localStorageItemKey: string;
    private user: User;

    constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, 
        private errorService: ErrorService) {
        this.apiUrl = environment.apiUrl;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.localStorageItemKey = 'current_klus_simpliq_user_data';

        this.router.routeReuseStrategy.shouldReuseRoute = function(){
            return false;
         }

        this.dataChange.subscribe((user) => {
                if (user != null) {
                    this.router.navigated = false;
                    this.router.navigate([this.router.url]);
                 }
             });
    }

    public getCachedUser(): User {
        if (this.user == null) {
            var localStorageItem = localStorage.getItem(this.localStorageItemKey);
            this.user = JSON.parse(localStorageItem)["data"];
        }
        return this.user;
    }

    public getUser(): Observable<User> {
        const localStorageItem = localStorage.getItem(this.localStorageItemKey);
        if (localStorageItem != null) {
            return Observable.of<User>(JSON.parse(localStorageItem)["data"]);
        } else {
            return this.getCurrentUser();
        }
    }

    updateUserLanguage(userId: number, languageId: number) {
        return this.http
            .put(this.apiUrl + '/users/' + userId, JSON.stringify({ languageId: languageId }), { headers: this.headers })
            .flatMap((userResponse: UserResponse) => {
                return this.getUserLanguages()
                .map(userLanguages => {
                    userResponse.data.language = userLanguages.find(item => item.id === userResponse.data.languageId).name;
                    //if admin
                    userResponse.data.availableLanguages = userLanguages;
                    localStorage.setItem(this.localStorageItemKey, JSON.stringify(userResponse));
                    return userResponse.data;
                });
              })
            .catch(this.errorService.handleError<any>('updateUserLanguage'));
    }

    dataChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    
    public removeCurrentUser() {
        localStorage.removeItem(this.localStorageItemKey);
    }

    private getCurrentUser(): Observable<User> {
        return this.http.get(this.apiUrl + '/user')
            .flatMap((userResponse: UserResponse) => {
                return this.getUserLanguages()
                .map(userLanguages => {
                    userResponse.data.language = userLanguages.find(item => item.id === userResponse.data.languageId).name;
                    //if admin
                    userResponse.data.availableLanguages = userLanguages;
                    localStorage.setItem(this.localStorageItemKey, JSON.stringify(userResponse));

                    return userResponse.data;
                });
              })
            .catch(this.errorService.handleError<any>('getCurrentUser'));
    }

    private getUserLanguages(): Observable<UserLanguage[]> {
        return this.http.get(this.apiUrl + '/languages')
            .pipe(
            tap((response) => {
                return response;
            }),
            catchError(this.errorService.handleError<any>('getUserLanguages'))
            );
    }

  changePassword(password: string) {
    return this.http
      .put(this.apiUrl + '/user/changePassword', JSON.stringify({ password: password }), { headers: this.headers })
      .catch(this.errorService.handleError<any>('changePassword'));
  }
}