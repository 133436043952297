<form [formGroup]="distributorForm" (ngSubmit)="onSubmit()" novalidate  #dform="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div *ngIf="name.invalid && (name.dirty || name.touched || dform.submitted)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="type">Type</label>
              <select class="form-control" formControlName="type" id="type">
                <option value="0">On-Line Partners</option>
                <option value="1">Representative</option>
                <option value="2">A/V Channel Reps</option>
              </select>
            </div>

            <div class="form-group">
              <label for="address">Address</label>
              <input type="text" class="form-control" id="address" formControlName="address">
              <div *ngIf="address.invalid  && (address.dirty || address.touched || dform.submitted)" class="alert alert-danger">
                <div *ngIf="address.errors.required ">
                  Address is required.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label for="name">City</label>
                <input type="text" class="form-control" id="city" formControlName="city">
              </div>
              <div class="form-group col-sm-4">
                <label for="name">Postal code</label>
                <input type="text" class="form-control" id="postalCode" formControlName="postalCode">
              </div>
            </div>
            <div class="form-group">
              <label for="countryId">Country</label>
              <select class="form-control" id="countryId" formControlName="country_id">
                <option value="">None</option>
                <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
              </select>
              <div *ngIf="country_id.invalid && (country_id.dirty || country_id.touched || dform.submitted)" class="alert alert-danger">
                <div *ngIf="country_id.errors.required">
                  Country is required.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="tel">Tel</label>
                <input type="text" class="form-control" id="tel" formControlName="tel">
              </div>
              <div class="form-group col-sm-6">
                <label for="tel2">Tel2</label>
                <input type="text" class="form-control" id="tel2" formControlName="tel2">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="email">email</label>
                <input type="text" class="form-control" id="email" formControlName="email">
              </div>
              <div class="form-group col-sm-6">
                <label for="www">www</label>
                <input type="text" class="form-control" id="www" formControlName="www">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Latitude</label>
                <input type="text" class="form-control" id="latitude" formControlName="latitude">
              </div>
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Longitude</label>
                <input type="text" class="form-control" id="longitude" formControlName="longitude">
              </div>
            </div>
            <div class="form-group">
              <input type="checkbox" id="isActive" name="isActive" formControlName="isActive" /> Active
            </div>
          </div>
        </div>
        <div class="card">

          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-warning mr-1" (click)="onCancel()">
              <i class="fa fa-ban"></i> Cancel</button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>