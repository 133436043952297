/**
 * Created by hawlik on 08.02.18.
 */
import {Country} from './country/country.model';

export class Distributor {
  constructor(public id: number,
              public name: string,
              public address: string,
              public postalCode: string,
              public city: string,
              public country_id: number,
              public country: Country,
              public tel: string,
              public tel2: string,
              public email: string,
              public www: string,
              public type: number,
              public latitude: string,
              public longitude: string,
              public isActive: boolean) {
  }
}
