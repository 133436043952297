import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
//import decode from 'jwt-decode';
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

import {environment} from 'environments/environment';
import 'rxjs/add/operator/map';
import {catchError, tap} from 'rxjs/operators';
import {ErrorService} from 'app/error.service';
import {UserService} from 'app/user/user.service';

@Injectable()
export class AuthenticationService {
    private apiUrl: string;

    private localStorageItemKey: string;
    // jwtHelper: JwtHelper = new JwtHelper();

    constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, 
        private errorService: ErrorService, private userService: UserService) {
        this.apiUrl = environment.apiUrl;
        this.localStorageItemKey = 'current_klus_simpliq_user';
    }

    public login(username: string, password: string): Observable<Object> {
        let body = new FormData();
        body.append('grant_type', 'password');
        body.append('client_id', '2');
        body.append('client_secret', 'IyNrWegT8oHW9au0D9hkufIR6MBLdTcAVy8OgdfO');
        body.append('email', username);
        body.append('password', password);

        return this.http.post(this.apiUrl + '/login', body)
            .pipe(
            tap((response) => {
                if (response && response["access_token"]) {
                    localStorage.setItem(this.localStorageItemKey, JSON.stringify(response));
                }
                return response;
            }),
            catchError(this.errorService.handleError<any>('login'))
            );
    }

    public logout() {
        localStorage.removeItem(this.localStorageItemKey);
        this.userService.removeCurrentUser();
    }

    public getToken(): string {
        var localStorageItem = localStorage.getItem(this.localStorageItemKey);
        if (localStorageItem != null) {
            return JSON.parse(localStorageItem)["access_token"];
        }
        return null;
    }

    public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();

        return token != null;// && !this.jwtHelper.isTokenExpired(token);
    }
}