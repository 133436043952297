import {NgModule} from '@angular/core';

import {DistributorsRoutingModule} from './distributors-routing.module';
import {DistributorsListComponent} from './distributors-list/distributors-list.component';
import {DistributorService} from './distributor.service';
import {AddDistributorComponent} from './distributor-add/add-distributor.component';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountryService} from './country/country.service';
import {EditDistributorComponent} from './distributor-edit/edit-distributor.component';
import {CountriesListComponent} from './country/country-list/countries-list.component';
import {AddCountryComponent} from './country/country-add/add-country.component';
import {EditCountryComponent} from './country/country-edit/edit-country.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    DistributorsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
   SharedModule,
  ],
  declarations: [ DistributorsListComponent, AddDistributorComponent, EditDistributorComponent,
    CountriesListComponent, AddCountryComponent, EditCountryComponent ],
  entryComponents: [
  ],
  providers: [
    DistributorService, CountryService, DatePipe
  ]
})
export class DistributorsModule { }
