import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import 'rxjs/Rx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorService} from '../../../error.service';
import {User} from '../../../user/user.model';
import {UserService} from '../../../user/user.service';
import {Country} from './country.model';
import {Observable} from 'rxjs/Observable';
import {ApiGetCountriesResponse, ApiGetCountryResponse} from '../api/api-country-responses.model';
import {AddCountry} from './country-add/Addcountry.model';


@Injectable()
export class CountryService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getCountries(): Observable<Country[]> {
    return this.http.get(this.apiUrl + '/countries?langId=' + this.user.languageId)
      .map((response) => {
        const listCountries: Country[] = [];
        for (const distributor of (new ApiGetCountriesResponse(response)).getData()) {
          const row = new Country(distributor.id, distributor.name);
          listCountries.push(row);
        }
        return listCountries
      })
      .catch(this.errorService.handleError<any>('getDistributors'));
  }
  addCountry(country: AddCountry): Observable<Country> {
    return this.http
      .post(this.apiUrl + '/countries?langId=' + this.user.languageId, JSON.stringify(country), {headers: this.headers})
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('addCountry'));
  }

  getCountry(countryId: number): Observable<Country> {
    return this.http.get(this.apiUrl + '/countries/' + countryId + '?langId=' + this.user.languageId)
      .map((response) => {
        const country = (new ApiGetCountryResponse(response)).getData();
        return new Country(country.id, country.name);
      })
      .catch(this.errorService.handleError<any>('getDistributor'));
  }

  updateCountry(countryId: number, country: AddCountry): Observable<ApiGetCountryResponse> {
    return this.http
      .put(this.apiUrl + '/countries/' + countryId + '?langId=' + this.user.languageId,
        JSON.stringify(country), {headers: this.headers})
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateCountry'));
  }

  deleteCountry(countryId: number) {
    return this.http
      .delete(this.apiUrl + '/countries/' + countryId + '?langId=' + this.user.languageId, {headers: this.headers})
      .catch(this.errorService.handleError<any>('updateCountry'));
  }
}
