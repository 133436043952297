import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {environment} from 'environments/environment';
import 'rxjs/Rx';
import {AddDistributor} from './distributor-add/AddDistributor.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorService} from '../../error.service';
import {User} from '../../user/user.model';
import {UserService} from '../../user/user.service';
import {Distributor} from './Distributor.model';
import {ApiDistributor, ApiGetDistributorResponse} from './api/api-distributor-responses.model';
import {CountryService} from './country/country.service';
import {Country} from './country/country.model';


@Injectable()
export class DistributorService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService,
              private countryService: CountryService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getDistributors(): Observable<Distributor[]> {

    return Observable.forkJoin([
      this.countryService.getCountries(),
      this.http.get(this.apiUrl + '/distributorsApi?langId=' + this.user.languageId)
    ])
      .map((data: any[]) => {
        const countries: Country[] = data[0];
        const distributors: ApiDistributor[] = data[1];
        const listDistributors: Distributor[] = [];
        for (const distributor of distributors) {
          const countrId:number = Number(distributor.country_id);
          const type:number = Number(distributor.type);
          const country: Country = countries.filter(c => c.id === countrId)[0];

          const row = new Distributor(
            distributor.id, distributor.name, distributor.address, distributor.postalCode, distributor.city,
            countrId, country,
            distributor.tel, distributor.tel2, distributor.email,
            distributor.www, type, distributor.latitude, distributor.longitude, distributor.isActive
          );
          listDistributors.push(row);
        }
        return listDistributors;
      })
      .catch(this.errorService.handleError<any>('getDistributors'));
  }

  addDistributor(distributor: AddDistributor): Observable<AddDistributor> {
    return this.http
      .post(this.apiUrl + '/distributorsApi?langId=' + this.user.languageId, JSON.stringify(distributor), {headers: this.headers})
      .catch(this.errorService.handleError<any>('addDistributor'));
  }

  getDistributor(distributorId: number): Observable<Distributor> {
    return this.http.get(this.apiUrl + '/distributorsApi/' + distributorId + '?langId=' + this.user.languageId)
      .map((response) => {
        const distributor = (new ApiGetDistributorResponse(response)).getData();
        return new Distributor(distributor.id, distributor.name, distributor.address, distributor.postalCode, distributor.city,
          distributor.country_id, null,  distributor.tel, distributor.tel2, distributor.email, distributor.www,
          distributor.type, distributor.latitude, distributor.longitude, distributor.isActive);
      })
      .catch(this.errorService.handleError<any>('getDistributor'));
  }

  updateDistributor(distributorId: number, distributor: Distributor): Observable<ApiGetDistributorResponse> {
    return this.http
      .put(this.apiUrl + '/distributorsApi/' + distributorId + '?langId=' + this.user.languageId,
        JSON.stringify(distributor), {headers: this.headers})
      .catch(this.errorService.handleError<any>('updateDistributor'));
  }

  deleteDistributor(distributorId: number) {
    return this.http
      .delete(this.apiUrl + '/distributorsApi/' + distributorId + '?langId=' + this.user.languageId, {headers: this.headers})
      .catch(this.errorService.handleError<any>('updateDistributor'));
  }
}
